<template>
    <div></div>
</template>

<script>
import { getSavedLocale } from "@/util/locate"

export default {
    name: "Login",
    methods: {
        startLogin() {
            this.$router.push({
                name: "Overview",
                params: { lang: "en" }
            })
        },
        login() {
            this.$auth0.loginWithRedirect()
        }
    },
    mounted() {
        this.login()
    }
}
</script>

<style scoped></style>
